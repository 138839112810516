<template>
  <el-button
    type="info"
    size="large"
    v-show="Store.state.popover.city && Store.state.popover.visible"
    class="cityPopover"
    :style="{
      top: Store.state.popover.top + 'px',
      left: Store.state.popover.left + 'px',
    }"
  >
    {{ Store.state.popover.city }}
  </el-button>
</template>
<script setup>
import { useStore } from "vuex";
import { Location } from "@element-plus/icons-vue";

const Store = useStore();
</script>

<style scoped lang="scss">
$dark-color: rgba(20, 26, 34, 0.9);

.cityPopover {
  position: absolute;
  /* width: 100px; */
  /* height: 100px; */
  display: block;
  z-index: 100;
  font-size: 16px;
  background-color: $dark-color;
}
</style>