<template>
  <!-- 地区的项目统计信息弹窗 -->
  <el-dialog
    v-model="Store.state.SystemIntroductionVisible"
    title="关于本项目"
    width="60%"
    center
    custom-class="areaIntroDialog"
  >
    <main class="introduction">
      <div class="article">
        <p>
          湖南大学设计艺术学院在“一带一路”主题上进行了大量研究，研究项目达到1186，涉及85个国家和地区。内容丰富，涉及了图钉墙国家非遗文化作业荚、新通道设计与社会创新营、ID
          Channel
          社会与文化专栏，包含了报告、设计、活动、视频等多种类别。我们特此进行了整理与汇总，集中展示所有资源。
        </p>
        <div class="logowall">
          <img src="@/assets/logo_wall.png" alt="" />
        </div>
      </div>
      <div class="footer"></div>
    </main>
  </el-dialog>
</template>

<script setup>
import { useStore } from "vuex";
const Store = useStore();
// const closeDialog = () => {
//   Store.commit("hideSystemIntroduction");
// };
</script>

<style lang="scss">
$theme-color: #15def8;
$dark-color: rgba(20, 26, 34, 0.9);

.areaIntroDialog {
  background: $dark-color;

  .el-dialog__headerbtn {
    .el-dialog__close {
      color: $theme-color;
      font-size: 40px;
    }
  }

  .el-dialog__header {
    padding: 60px 20px;

    .el-dialog__title {
      color: #fff;
      padding: 0;
      font-size: 48px;
      font-weight: bold;
      line-height: 1em;
    }
  }
  .el-dialog__body {
    color: #ccc;
    padding: 70px;
    padding-top: 0;
    font-size: 20px;
    line-height: 2em;
    letter-spacing: 0.2em;

    .logowall {
      height: 100px;
      img {
        width: 100%;
      }
    }
  }
  .el-dialog__footer {
    padding: 30px;

    .dialogfooterbtn {
      height: 60px;
      width: 150px;
      color: $dark-color;
      font-weight: bold;
    }
  }
}
</style>