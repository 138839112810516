<template>
  <transition name="fade">
    <div class="videoBG" v-show="Store.state.selectedCity">
      <video poster="" autoplay muted loop id="videoBG">
        <source src="@/assets/videoBG.mp4" type="video/mp4" />
      </video>
      <h3 class="bgTitle">{{ Store.state.selectedCity }}</h3>
    </div>
  </transition>
</template>
<script setup>
import { useStore } from "vuex";
import { Location } from "@element-plus/icons-vue";

const Store = useStore();
</script>

<style scoped lang="scss">
$dark-color: rgba(20, 26, 34, 0.9);

.fade-enter-active,
.fade-leave-active {
  transition: all 0.4s ease-out;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: scale(1.2);
}

.videoBG {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
  font-size: 0;
  z-index: 9;
  background-color: $dark-color;
  overflow: hidden;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .bgTitle {
    width: 100%;
    font-size: 160px;
    font-weight: bold;
    text-align: right;
    position: absolute;
    color: rgba(255, 255, 255, 0.5);
    bottom: -0.4em;
    letter-spacing: -0.2em;
    right: 20px;
    font-family: Baskerville, Georgia, "Liberation Serif", "Kaiti SC", STKaiti,
      "AR PL UKai CN", "AR PL UKai HK", "AR PL UKai TW", "AR PL UKai TW MBE",
      "AR PL KaitiM GB", KaiTi, KaiTi_GB2312, DFKai-SB, TW-Kai, serif;
  }
}
.videoBG::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    118deg,
    rgb(20 30 35 / 96%) 24%,
    rgb(28 167 194 / 30%) 100%
  );
}
</style>